@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #1f1f1f;
}
/* Style for Webkit-based browsers (e.g. Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Style for Firefox */
::-moz-scrollbar {
  width: 4px;
}

::-moz-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-moz-scrollbar-thumb:hover {
  background-color: #555;
}





